<template>
  <div class="nav_label main_pl_pr clearfix">

    <div class="logo fl">
      <img src="@/assets/logo.png" alt="">
    </div>
    <div class="nav_layout fr">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#ec1c24" >
        <el-menu-item index="/home">首页</el-menu-item>
        <el-menu-item index="2">关于我们</el-menu-item>
        <el-menu-item index="3">我的产品</el-menu-item>
        <el-menu-item index="4">新闻动态</el-menu-item>
        <el-menu-item index="5">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
  export default {
    name: "navLabel",
    data(){
      return{
        activeIndex:'/home'
      }
    }
  }
</script>

<style scoped>

</style>
