<template>
  <div class="home_box">
    <!--  首页-->
    <div class="banner_carousel">
      <el-carousel height="45rem">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item.src" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="culture_box main_pl_pr clearfix">
      <div class="left fl">
        <div class="english_title">
          <div class="chinese">企业文化</div>
          <div>Culture</div>
        </div>

        <div class="p_content">
          <p>以积极乐观的心态面对日常工作</p>
          <p>不断提高要求，自我完善</p>
          <p>在更大范围里找最优解，不放过问题，思考本质</p>
          <p>持续学习和成长</p>

        </div>
      </div>
      <div class="right fr">
        <el-carousel height="40rem" indicator-position="none">
          <el-carousel-item v-for="(item,i) in cultureList" :key="i">
            <div class="item_box">
              <img :src="item.src" alt="">
              <div class="content">
                <div class="title">{{item.title}}</div>
                <div class="content">
                  <p v-for="v in item.options">{{v}}</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="time_line main_pl_pr">
      <div class="english_title">
        <div class="chinese">大事记</div>
        <div>Memorabilia</div>
      </div>
      <div class="main_line tc">
        <div class="content tc">
          <div class="desc_card clearfix"
               :style=" {backgroundImage:'url('+timeLineList[timeIndex].bgImg+')',backgroundSize:'100% 100%' }">
            <div class="line_desc fr">
              <p class="tit">{{timeLineList[timeIndex].year}}</p>
              <p>{{timeLineList[timeIndex].info}}</p>
            </div>
          </div>

        </div>

        <div class="ul_box">
          <ul class="my_timeline" ref="mytimeline" style="margin-left: 10px;">
            <li class="my_timeline_item" v-for="(item,index) in timeLineList" :key="index"
                :style=" { width: 100/timeLineList.length + '%'}">
              <!--圈圈节点-->
              <div class="my_timeline_node"
                   @click="changeActive(index)">
                <img :src="item.iconImg" alt="">
              </div>
              <!--              :class="{active: index == timeIndex}"-->
              <!--              :style=" {backgroundColor: item.bgcolor, width: item.size + 'px', height: item.size + 'px'}"-->
              <!--线-->
              <div class="my_timeline_item_line"></div>

            </li>
          </ul>
        </div>

      </div>
    </div>
    <div class="contact main_pl_pr">
      <div class="english_title">
        <div class="chinese">联系我们</div>
        <div>Contact us</div>
      </div>
      <el-row>
        <el-col :span="8">
          <div class="title"><span class="black">邮箱</span><span>E-mail</span></div>
        </el-col>
        <el-col :span="8">
          <div class="title"><span class="black">电话</span><span>TEL</span></div>
        </el-col>
        <el-col :span="8">
          <div class="title"><span class="black">地址</span><span>Address</span></div>
        </el-col>
        <el-col :span="8">
          <div class="number_desc">0451-51171338</div>
        </el-col>
        <el-col :span="8">
          <div class="number_desc">0451-51171338</div>
        </el-col>
        <el-col :span="8">
          <div class="number_desc">松北区松北大道11号</div>
        </el-col>
      </el-row>
    </div>
    <div class="home_fotter main_pl_pr">
      <div class="logo">
        <img src="@/assets/logoW.png" alt="">
      </div>
      <p>哈尔滨城智科技应用有限公司</p>
      <p>黑ICP备2020005577号</p>
      <div class="icon blog"><img src="@/assets/footerIcon/blog.png" alt=""></div>
      <div class="icon wechart"><img src="@/assets/footerIcon/wechart.png" alt=""></div>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'Home',
    data() {
      return {
        bannerList: [{src: require("@/assets/banner/banner1.jpg"),}, {src: require("@/assets/banner/banner2.jpg"),}],
        cultureList: [
          {
            src: require("@/assets/culture/img1.png"),
            title: '拼搏人生',
            options: ['不是所有的拼搏都会成功', '我们不能盲目的拼搏，务必带上我们的智慧', '将属于我们的机会牢牢抓住', '才会多一份成功']
          },
          {
            src: require("@/assets/culture/img2.png"),
            title: '进取之心',
            options: ['凡真正强者', '或许不一定会是成功者', '但其必定有一颗强烈的进取之心', '它可以使我们在失败中越挫越勇']
          },
        ],
        timeIndex: 1,
        timeLineList: [
          {
            timestamp: '2012年',
            color: '#999',
            fontsize: 18,
            size: '28',
            bgcolor: '#e4e7ed',
            icon: 'el-iconprev',
            year: '2020年5月',
            info: '公司注册成立',
            bgImg: require("../assets/timeline/1.png"),
            iconImg: require("@/assets/timeline/icon/1.png"),
          }, {
            timestamp: '2013年',
            color: '#999',
            fontsize: 18,
            size: '28',
            bgcolor: '#e4e7ed',
            year: '2020年9月',
            info: '哈尔滨市房屋交易综合服务平台上线',
            bgImg: require("../assets/timeline/2.png"),
            iconImg: require("@/assets/timeline/icon/2.png"),
          },]
      }
    },
    methods: {
      changeActive(index) {
        this.timeIndex = index;
      },
      moveLeft() {
        let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
        let listNum = 0;
        if (marginLeft <= 10 && (marginLeft >= -650)) {
          this.$refs.mytimeline.style.marginLeft = marginLeft - 220 + 'px';
        }
      },
      moveRight() {
        let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
        if (marginLeft < (-200)) {
          this.$refs.mytimeline.style.marginLeft = marginLeft + 220 + 'px';
        }
      }
    }

  }
</script>
<style scoped="home_box" lang="less">
  .home_box {
    .banner_carousel {

    }

    .english_title {
      font-weight: bold;
      font-size: 7rem;
      color: rgba(134, 134, 134, .2);
      position: relative;
      text-align: left;

      .chinese {
        position: absolute;
        font-size: 3rem;
        color: #333333;
      }
    }

    .culture_box {
      color: #666666;
      padding-top: 10rem;

      .left {
        padding-top: 3rem;

        .english_title {
          font-weight: bold;
          font-size: 7rem;
          color: rgba(134, 134, 134, .2);
          position: relative;

          .chinese {
            position: absolute;
            font-size: 3rem;
            color: #333333;
          }
        }
      }

      .right {
        border-right: 2rem;
        width: 55%;
        height: 40rem;

        .item_box {
          height: 100%;

          img {
            height: 25rem;
            width: 100%;
          }

          .title {
            font-weight: 500;
            font-size: 1.8rem;
            color: #333;
          }
        }
      }
    }

    .time_line {
      overflow: hidden;
      text-align: left;
      padding-top: 3rem;
      padding-bottom: 3rem;

      .main_line {
        .content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-bottom: 1.5rem;
        }

        .desc_card {
          width: 50rem;
          height: 20rem;

          .line_desc {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .tit {
              color: #222;
              font-size: 2.5rem;
              font-weight: 500;
            }
          }
        }

        .ul_box {
          width: 100%;
          /*height: 60px;*/
          display: inline-block;
          float: left;
          margin-top: 2px;
        }

        .my_timeline_item {
          display: inline-block;
          position: relative;
        }

        .my_timeline_node {
          box-sizing: border-box;
          border-radius: 50%;
          cursor: pointer;
          position: absolute;
          width: 5.5rem;
          height: 5.5rem;
          top: -2.75rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .my_timeline_item_line {
          width: 100%;
          height: 10px;
          margin: -14px 0 0 28px;
          border-top: 2px solid #E4E7ED;
          border-left: none;
        }

        /*<!--.my_timeline_item_content {-->*/
        /*<!--  margin: 10px 0 0 -10px;-->*/
        /*<!--}-->*/
      }
    }

    .contact {
      padding-bottom: 3rem;

      .el-row {
        .el-col {
          margin-bottom: 1rem;
        }

        .title {
          color: #999;

          .black {
            color: #333;
            font-weight: 700;
            font-size: 1.5rem;
          }
        }

        .number_desc {
          color: #2d55ff;
        }
      }
    }

    .home_fotter {
      text-align: left;
      background-color: #1f2329;
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: #7b7e81;
      position: relative;

      .icon {
        position: absolute;
        width: 3rem;
        height: 3rem;
        bottom: 2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .blog {
        right: 8%;
      }

      .wechart {
        right: calc(5rem + 8%);
      }
    }
  }
</style>
